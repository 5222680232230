import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/FileUploadOutlined';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { type ChangeEvent, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { WorkspacesIcon } from 'src/assets/WorkspacesIcon';
import { OverflowTooltipTypography } from 'src/components/OverflowTooltipTypography';
import type { GtmEvoOutputObject, GtmProjectInput } from 'src/gtmProject';
import { useSceneObjectDataManager } from 'src/hooks';
import { useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import { selectUserTriangleMeshes, selectWorkspaceName } from 'src/store/evo/selectors';
import { addInputObjectsToProject, updateInputObject } from 'src/store/project/projectSlice';
import { selectProjectInputObjects } from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    addToSelectedWorkspaceObjectIds,
    clearSelectedWorkspaceObjectIds,
    removeFromSelectedWorkspaceObjectIds,
    selectSelectedWorkspaceObjectIds,
    selectShouldMinimizeProjectPanel,
    selectWorkspaceSearchTerm,
    setOpenUploadObjectsDialog,
    setSelectedWorkspaceObjectIds,
    setWorkspaceSearchTerm,
    switchToProjectMode,
} from 'src/store/ui/projectPanel';
import { DEFAULT_LIST_MAX_HEIGHT } from 'src/styles';
import { fileNameExtensionRemover } from 'src/utils';
import { ProjectSelector } from 'src/visualization/ProjectPanel/components/ProjectView/ProjectSelector';
import { UploadObjectsDialog } from 'src/visualization/ProjectPanel/components/UploadObjectsDialog';
import {
    ADD_LABEL,
    ADDED_LABEL,
    BACK_LABEL,
    getAddObjectsToProjectDescription,
    getAddObjectToProjectDescription,
    NO_OBJECTS_YET_MESSAGE,
    UPLOAD_LABEL,
    WORKSPACE_SEARCH_PLACEHOLDER,
} from 'src/visualization/ProjectPanel/ProjectPanel.constants';

export const WorkspaceView = () => {
    const applyTrace = useTrace('workspace-view');
    const shouldMinimizeProjectPanel = useAppSelector(selectShouldMinimizeProjectPanel);

    return (
        <>
            <ProjectSelector />
            <Divider />
            {!shouldMinimizeProjectPanel && (
                <WorkspacePanel automation-id={applyTrace('workspace-panel')} />
            )}
            <UploadObjectsDialog />
        </>
    );
};

function WorkspacePanel() {
    const applyTrace = useTrace('workspace-panel');
    const dispatch = useAppDispatch();
    const userMeshes = useAppSelector(selectUserTriangleMeshes);
    const workspaceSearchTerm = useAppSelector(selectWorkspaceSearchTerm);
    const projectInputObjects = useAppSelector(selectProjectInputObjects);
    const addedObjectIds = useMemo(
        () => projectInputObjects.map(({ id }) => id),
        [projectInputObjects],
    );
    const selectedWorkspaceObjectIds = useAppSelector(selectSelectedWorkspaceObjectIds);
    const userMeshIds = useMemo(() => userMeshes?.map(({ id }) => id), [userMeshes]) ?? [];
    const filteredUserMeshes = useMemo(
        () =>
            userMeshes?.filter((object) =>
                workspaceSearchTerm === ''
                    ? true
                    : object.name.toLowerCase().includes(workspaceSearchTerm.toLowerCase()),
            ) ?? [],
        [userMeshes, workspaceSearchTerm],
    );
    const areAllObjectsSelected =
        filteredUserMeshes.length === selectedWorkspaceObjectIds.length + addedObjectIds.length;
    const selectedUserMeshes = useMemo(
        () => userMeshes?.filter((object) => selectedWorkspaceObjectIds.includes(object.id)) ?? [],
        [userMeshes, selectedWorkspaceObjectIds],
    );
    const { loadGtmObject } = useSceneObjectDataManager();
    const { syncProject } = useProjectSynchronizer();
    const workspaceName = useAppSelector(selectWorkspaceName);

    if (userMeshes && userMeshes.length === 0) {
        return (
            <Stack p={4} display="flex" alignItems="center">
                <Typography>{NO_OBJECTS_YET_MESSAGE}</Typography>
                <Stack direction="row" spacing={2} mt={3}>
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                            dispatch(switchToProjectMode());
                        }}
                    >
                        {BACK_LABEL}
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        startIcon={<UploadIcon />}
                        onClick={() => {
                            dispatch(setOpenUploadObjectsDialog(true));
                        }}
                    >
                        {UPLOAD_LABEL}
                    </Button>
                </Stack>
            </Stack>
        );
    }

    const handleAddSelectedObjects = () => {
        const inputObjects: GtmProjectInput[] = [];
        selectedUserMeshes.forEach((object) => {
            inputObjects.push(object);
            loadGtmObject(object.id, object.version, object.name);
        });
        dispatch(addInputObjectsToProject(inputObjects));
        dispatch(clearSelectedWorkspaceObjectIds());
        inputObjects.forEach((object) => {
            dispatch(updateInputObject([object.id, { ...object, visible: true }]));
        });
        syncProject({
            description: getAddObjectsToProjectDescription(inputObjects),
        });
    };

    const handleSelectOrDeselectAllObjects = () => {
        if (areAllObjectsSelected) {
            dispatch(setSelectedWorkspaceObjectIds([]));
        } else {
            dispatch(
                setSelectedWorkspaceObjectIds(
                    filteredUserMeshes
                        .filter(({ id }) => !addedObjectIds.includes(id))
                        .map(({ id }) => id),
                ),
            );
        }
    };

    return (
        <Box automation-id={applyTrace('root')} padding={(theme) => theme.spacing(1, 2, 2)}>
            <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <Stack direction="row" spacing={1}>
                    <WorkspacesIcon />
                    <Typography>{workspaceName}</Typography>
                </Stack>
                <IconButton
                    automation-id={applyTrace('close-button')}
                    size="small"
                    onClick={() => {
                        dispatch(switchToProjectMode());
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Stack>
            <Box width="100%" mt={2} mb={1}>
                <WorkspaceSearchInput />
            </Box>
            <Stack
                direction="row"
                sx={(theme) => ({
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: theme.spacing(0, 1, 0.5),
                })}
            >
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                    <Checkbox
                        size="small"
                        disabled={addedObjectIds.length === userMeshIds.length}
                        checked={areAllObjectsSelected}
                        indeterminate={
                            selectedWorkspaceObjectIds.length > 0 && !areAllObjectsSelected
                        }
                        onChange={handleSelectOrDeselectAllObjects}
                    />
                    <Typography variant="caption">
                        {selectedWorkspaceObjectIds.length} selected
                    </Typography>
                </Stack>
                <Button
                    automation-id={applyTrace('add-selected-objects-button')}
                    size="small"
                    onClick={handleAddSelectedObjects}
                    variant="text"
                    startIcon={<AddIcon />}
                    disabled={selectedWorkspaceObjectIds.length === 0}
                >
                    {ADD_LABEL}
                </Button>
            </Stack>
            <Box
                sx={{
                    maxHeight: DEFAULT_LIST_MAX_HEIGHT,
                    overflowY: 'auto',
                    borderRadius: 0.5,
                    border: 1,
                    borderColor: 'divider',
                }}
            >
                <List dense disablePadding>
                    {filteredUserMeshes.map((object, index) => (
                        <WorkspaceObjectListItem
                            key={object.id}
                            workSpaceObject={object}
                            isLastItem={index === filteredUserMeshes.length - 1}
                        />
                    ))}
                </List>
            </Box>
        </Box>
    );
}

const WorkspaceObjectListItem = ({
    workSpaceObject,
    isLastItem,
}: {
    workSpaceObject: GtmEvoOutputObject;
    isLastItem?: boolean;
}) => {
    const applyTrace = useTrace('workspace-object-list-item');
    const dispatch = useAppDispatch();
    const { loadGtmObject } = useSceneObjectDataManager();
    const projectInputObjects = useAppSelector(selectProjectInputObjects);
    const isAdded = useMemo(
        () => projectInputObjects.some((object) => object.id === workSpaceObject.id),
        [projectInputObjects, workSpaceObject],
    );
    const { syncProject } = useProjectSynchronizer();
    const selectedWorkspaceObjectIds = useAppSelector(selectSelectedWorkspaceObjectIds);
    const isSelectedToAdd = selectedWorkspaceObjectIds.includes(workSpaceObject.id);

    const createOnAddClickHandler = (object: GtmEvoOutputObject) => () => {
        loadGtmObject(object.id, object.version, object.name);
        dispatch(addInputObjectsToProject([object]));
        dispatch(updateInputObject([object.id, { ...object, visible: true }]));
        syncProject({
            description: getAddObjectToProjectDescription(object),
        });
    };

    const handleOnSelectToAdd = () => {
        if (isSelectedToAdd) {
            dispatch(removeFromSelectedWorkspaceObjectIds(workSpaceObject.id));
        } else {
            dispatch(addToSelectedWorkspaceObjectIds(workSpaceObject.id));
        }
    };

    return (
        <ListItem disableGutters disablePadding divider={!isLastItem}>
            <ListItemButton
                selected={isSelectedToAdd}
                disabled={isAdded}
                sx={(theme) => ({ padding: theme.spacing(0, 1) })}
            >
                <Checkbox
                    automation-id={applyTrace(
                        `checkbox-${fileNameExtensionRemover(workSpaceObject.name)}`,
                    )}
                    disabled={isAdded}
                    size="small"
                    checked={isSelectedToAdd || isAdded}
                    onChange={handleOnSelectToAdd}
                />
                <ListItemText
                    disableTypography
                    primary={
                        <OverflowTooltipTypography variant="body2">
                            {fileNameExtensionRemover(workSpaceObject.name)}
                        </OverflowTooltipTypography>
                    }
                />
                <Button
                    size="small"
                    onClick={createOnAddClickHandler(workSpaceObject)}
                    variant="text"
                    startIcon={isAdded ? null : <AddIcon />}
                    disabled={isAdded}
                >
                    {isAdded ? ADDED_LABEL : ADD_LABEL}
                </Button>
            </ListItemButton>
        </ListItem>
    );
};

const WorkspaceSearchInput = () => {
    const applyTrace = useTrace('workspace-search-input');
    const dispatch = useAppDispatch();
    const workspaceSearchTerm = useSelector(selectWorkspaceSearchTerm);

    const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(setWorkspaceSearchTerm(event.target.value));
    };

    return (
        <TextField
            sx={{ width: '100%' }}
            automation-id={applyTrace()}
            placeholder={WORKSPACE_SEARCH_PLACEHOLDER}
            size="small"
            value={workspaceSearchTerm}
            onChange={handleSearchInputChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
};
