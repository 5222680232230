import { useMessagesContext } from '@local/messages/dist/MessagesContext';
import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import { fetchWorkspaces } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import { getHubUrlForCurrentOrg } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { lighten } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import type { ChangeEvent } from 'react';
import { useMemo, useState } from 'react';
import { FormattedDate } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { useListFilesQuery } from 'src/apiClients/file/enhancedFileMiddleware';
import { ExploreContentIcon } from 'src/assets/ExploreContentIcon';
import { FolderCloudIcon } from 'src/assets/FolderCloudIcon';
import { OverflowTooltipTypography } from 'src/components/OverflowTooltipTypography';
import { useProjectManager } from 'src/hooks/project';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    selectShouldOpenNoProjectDialog,
    setShouldOpenNoProjectDialog,
    setShouldShowProjectPanel,
} from 'src/store/ui/projectPanel';
import { PROJECT_EXTENSION } from 'src/strings';
import { fileNameExtensionRemover } from 'src/utils';
import {
    BROWSE_PROJECTS_LABEL,
    NEW_PROJECT_LABEL,
    NO_PROJECTS_AVAILABLE,
    NO_PROJECTS_MESSAGE,
    NO_PROJECTS_TITLE,
} from 'src/visualization/ProjectPanel/components/NoProjectsDialog/NoProjectsDialog.constants';
import {
    NEW_PROJECT_NAME,
    PROJECT_INITIALIZING_ERROR,
    WORKSPACE_SEARCH_PLACEHOLDER,
} from 'src/visualization/ProjectPanel/ProjectPanel.constants';

export function NoProjectsDialog() {
    const applyTrace = useTrace('no-projects-dialog');
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const shouldOpenNoProjectDialog = useAppSelector(selectShouldOpenNoProjectDialog);
    const { orgUuid, workspaceUuid, hubCode } = useParams();
    const { data: workspaceData } = fetchWorkspaces({
        isAdmin: false,
        hubUrl: getHubUrlForCurrentOrg(),
        orgId: orgUuid!,
        limit: 500,
        sort: 'name',
    });
    const workspaceItems =
        workspaceData?.results
            .filter(({ id }) => id !== workspaceUuid)
            .map(({ name, id }) => ({
                name,
                id,
            })) || [];
    const [selectedWorkspaceUuid, setSelectedWorkspaceUuid] = useState(workspaceItems[0]?.id ?? '');
    const [projectSearchTerm, setProjectSearchTerm] = useState('');
    const { data: projectFilesData, isFetching: areProjectsLoading } = useListFilesQuery(
        {
            organisationId: orgUuid!,
            workspaceId: selectedWorkspaceUuid,
            fileName: `.${PROJECT_EXTENSION}`,
        },
        { skip: !selectedWorkspaceUuid },
    );
    const filteredProjectData = useMemo(
        () =>
            projectFilesData?.files.filter((file) =>
                file.name.toLowerCase().includes(projectSearchTerm.toLowerCase()),
            ) ?? [],
        [projectFilesData, projectSearchTerm],
    );
    const { initializeNewProjectFile } = useProjectManager();
    const { addMessage } = useMessagesContext();
    const [isSearchingProjects, setIsSearchingProjects] = useState(false);
    const [isInitializing, setIsInitializing] = useState(false);

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedWorkspaceUuid(event.target.value);
    };

    const createSelectProjectHandler = (fileName: string) => () => {
        navigate(
            `/${orgUuid}/hub/${hubCode}/workspace/${selectedWorkspaceUuid}/project/${fileNameExtensionRemover(
                fileName,
            )}`,
        );
        dispatch(setShouldOpenNoProjectDialog(false));
        dispatch(setShouldShowProjectPanel(true));
    };

    const handleCreateNewProject = async () => {
        try {
            setIsInitializing(true);
            await initializeNewProjectFile(NEW_PROJECT_NAME);
            dispatch(setShouldOpenNoProjectDialog(false));
            dispatch(setShouldShowProjectPanel(true));
            navigate(
                `/${orgUuid}/hub/${hubCode}/workspace/${workspaceUuid}/project/${NEW_PROJECT_NAME}`,
            );
        } catch (e) {
            addMessage({
                message: PROJECT_INITIALIZING_ERROR,
                type: NotificationType.ERROR,
            });
        } finally {
            setIsInitializing(false);
        }
    };

    const handleSearchProjectsClick = () => {
        setIsSearchingProjects(true);
    };

    const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setProjectSearchTerm(event.target.value);
    };

    const handleCloseSearch = () => {
        setIsSearchingProjects(false);
        setProjectSearchTerm('');
    };

    return (
        <Dialog automation-id={applyTrace()} open={shouldOpenNoProjectDialog}>
            <Paper
                variant="outlined"
                sx={(theme) => ({
                    width: '350px',
                    margin: theme.spacing(2),
                    backgroundColor: lighten(theme.palette.background.paper, 0.1),
                    paddingTop: theme.spacing(3),
                    paddingBottom: theme.spacing(3),
                    textAlign: 'center',
                })}
            >
                <span style={{ fontSize: 34 }}>
                    <ExploreContentIcon fontSize="inherit" />
                </span>
                <Typography variant="h5">{NO_PROJECTS_TITLE}</Typography>
                <Typography
                    variant="body1"
                    sx={(theme) => ({
                        maxWidth: 235,
                        margin: 'auto',
                        marginTop: theme.spacing(1),
                        marginBottom: theme.spacing(2),
                    })}
                >
                    {NO_PROJECTS_MESSAGE}
                </Typography>
                <Button
                    disabled={isInitializing}
                    automation-id={applyTrace('new-project-button')}
                    variant="contained"
                    onClick={handleCreateNewProject}
                    startIcon={<AddIcon />}
                    size="small"
                >
                    {isInitializing ? <CircularProgress size={20} /> : NEW_PROJECT_LABEL}
                </Button>
            </Paper>
            <Divider />
            <Box sx={(theme) => ({ margin: theme.spacing(2), width: '350px' })}>
                <Typography
                    sx={(theme) => ({ marginBottom: theme.spacing(2) })}
                    variant="body1"
                >{`${BROWSE_PROJECTS_LABEL} (${projectFilesData?.files.length ?? 0})`}</Typography>
                {isSearchingProjects ? (
                    <Stack direction="row" spacing={2} mb={1}>
                        <TextField
                            sx={(theme) => ({
                                backgroundColor: lighten(theme.palette.background.paper, 0.1),
                                flexGrow: 1,
                                marginBottom: theme.spacing(1),
                            })}
                            placeholder={WORKSPACE_SEARCH_PLACEHOLDER}
                            size="small"
                            value={projectSearchTerm}
                            onChange={handleSearchInputChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <IconButton onClick={handleCloseSearch} size="small">
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                ) : (
                    <Stack direction="row" spacing={2} mb={1}>
                        <FormControl
                            sx={(theme) => ({
                                flexGrow: 1,
                                backgroundColor: lighten(theme.palette.background.paper, 0.1),
                            })}
                        >
                            <Select
                                size="small"
                                value={selectedWorkspaceUuid}
                                onChange={handleChange}
                                MenuProps={{ slotProps: { paper: { sx: { maxHeight: 400 } } } }}
                            >
                                {workspaceItems.map(({ name, id }) => (
                                    <MenuItem key={id} value={id}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <IconButton onClick={handleSearchProjectsClick} size="small">
                            <SearchIcon />
                        </IconButton>
                    </Stack>
                )}
                <Box
                    sx={{
                        height: '200px',
                        overflowY: 'auto',
                        borderRadius: 0.5,
                        border: 1,
                        borderColor: 'divider',
                    }}
                >
                    {areProjectsLoading ? (
                        <Box
                            width="100%"
                            height="100%"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            {filteredProjectData.length === 0 && (
                                <Box
                                    width="100%"
                                    height="100%"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Typography>{NO_PROJECTS_AVAILABLE}</Typography>
                                </Box>
                            )}

                            <List dense disablePadding>
                                {filteredProjectData.map((file) => (
                                    <ListItem
                                        sx={(theme) => ({
                                            backgroundColor: lighten(
                                                theme.palette.background.paper,
                                                0.1,
                                            ),
                                        })}
                                        key={file.file_id}
                                        disableGutters
                                        disablePadding
                                        divider
                                    >
                                        <ListItemButton
                                            sx={(theme) => ({ padding: theme.spacing(0, 1.5) })}
                                            onClick={createSelectProjectHandler(file.name)}
                                        >
                                            <ListItemIcon sx={{ minWidth: '40px' }}>
                                                <FolderCloudIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                                disableTypography
                                                primary={
                                                    <OverflowTooltipTypography variant="body2">
                                                        {fileNameExtensionRemover(file.name)}
                                                    </OverflowTooltipTypography>
                                                }
                                                secondary={
                                                    <span>
                                                        <OverflowTooltipTypography variant="caption">
                                                            <>
                                                                <FormattedDate
                                                                    value={file.created_at}
                                                                    month="short"
                                                                    year="numeric"
                                                                    day="2-digit"
                                                                />{' '}
                                                                &#183; {file.created_by?.name ?? ''}
                                                            </>
                                                        </OverflowTooltipTypography>
                                                    </span>
                                                }
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </>
                    )}
                </Box>
            </Box>
        </Dialog>
    );
}
