import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton/IconButton';
import Portal from '@mui/material/Portal';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';

import { useHistoryManager } from 'src/hooks/history/useHistoryManager';
import {
    selectIsSyncingProject,
    selectLastRedoEntry,
    selectLastUndoEntry,
} from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { selectIsCreatingBoundary } from 'src/store/ui/projectPanel';

export const UndoRedoActions = () => {
    const applyTrace = useTrace('undo-redo-actions');
    const { undoOperation, redoOperation } = useHistoryManager();
    const lastUndoEntry = useAppSelector(selectLastUndoEntry);
    const lastRedoEntry = useAppSelector(selectLastRedoEntry);
    const isSyncingProject = useAppSelector(selectIsSyncingProject);
    const isCreatingBoundary = useAppSelector(selectIsCreatingBoundary);
    const [openBackDrop, setOpenBackdrop] = useState(false);
    const shouldDisableActions = isSyncingProject || isCreatingBoundary;

    const handleUndo = async () => {
        setOpenBackdrop(true);
        await undoOperation();
        setOpenBackdrop(false);
    };

    const handleRedo = async () => {
        setOpenBackdrop(true);
        await redoOperation();
        setOpenBackdrop(false);
    };

    return (
        <>
            <Portal container={document.body}>
                <Backdrop sx={{ zIndex: 1500 }} open={openBackDrop}>
                    <CircularProgress />
                </Backdrop>
            </Portal>
            <Tooltip
                title={
                    lastUndoEntry?.operation.description &&
                    `Undo: ${lastUndoEntry.operation.description}`
                }
            >
                {/* span here since tooltip doesn't like disabled buttons */}
                <span>
                    <IconButton
                        automation-id={applyTrace('undo-button')}
                        sx={{ margin: 0.5, padding: 0.5 }}
                        size="small"
                        onClick={handleUndo}
                        disabled={!lastUndoEntry || shouldDisableActions}
                    >
                        <UndoIcon fontSize="small" />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip
                title={
                    lastRedoEntry?.operation.description &&
                    `Redo: ${lastRedoEntry.operation.description}`
                }
            >
                <span>
                    <IconButton
                        automation-id={applyTrace('redo-button')}
                        sx={{ margin: 0.5, padding: 0.5 }}
                        size="small"
                        onClick={handleRedo}
                        disabled={!lastRedoEntry || shouldDisableActions}
                    >
                        <RedoIcon fontSize="small" />
                    </IconButton>
                </span>
            </Tooltip>
        </>
    );
};
