import { useTrace } from '@local/web-design-system-2';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

import { CrossSectionIcon } from 'src/assets/CrossSectionIcon';
import { ParametricIcon } from 'src/assets/ParametricIcon';
import { TriangulatedIcon } from 'src/assets/TriangulatedIcon';
import { OverflowTooltipTypography } from 'src/components/OverflowTooltipTypography';
import type { GtmModelUnion } from 'src/gtmProject';
import { GtmModelType } from 'src/gtmProject';
import { selectCurrentProjectModels } from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { selectShouldMinimizeProjectPanel } from 'src/store/ui/projectPanel';
import { DEFAULT_LIST_MAX_HEIGHT_PX } from 'src/styles';
import { InputObjects } from 'src/visualization/ProjectPanel/components/ProjectView/InputObjects';
import { ProjectSelector } from 'src/visualization/ProjectPanel/components/ProjectView/ProjectSelector';
import { UploadObjectsDialog } from 'src/visualization/ProjectPanel/components/UploadObjectsDialog';
import { useGtmNavigator } from 'src/visualization/ProjectPanel/components/useGtmNavigator';
import { MODELS_LABEL } from 'src/visualization/ProjectPanel/ProjectPanel.constants';

export const ProjectView = () => {
    const shouldMinimizeProjectPanel = useAppSelector(selectShouldMinimizeProjectPanel);

    return (
        <>
            <ProjectSelector />
            <Divider />
            {!shouldMinimizeProjectPanel && (
                <>
                    <ProjectModelsList />
                    <Divider />
                    <InputObjects />
                    <UploadObjectsDialog />
                </>
            )}
        </>
    );
};

const ProjectModelsList = () => {
    const applyTrace = useTrace('project-models-list');
    const models = useAppSelector(selectCurrentProjectModels);
    const [isExpanded, setIsExpanded] = useState(true);

    if (models.length === 0) {
        return null;
    }

    return (
        <Box automation-id={applyTrace()} p={2} mb={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ textTransform: 'uppercase' }}
                >
                    {MODELS_LABEL}
                </Typography>
                <IconButton
                    sx={{ padding: 0 }}
                    size="small"
                    onClick={() => {
                        setIsExpanded((prevExpanded) => !prevExpanded);
                    }}
                >
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Stack>
            <Collapse in={isExpanded}>
                <Box
                    sx={{
                        maxHeight: DEFAULT_LIST_MAX_HEIGHT_PX,
                        overflowY: 'auto',
                        borderRadius: 0.5,
                        border: 1,
                        borderColor: 'divider',
                        mt: 2,
                    }}
                >
                    <List dense disablePadding>
                        {models.map((model, index) => (
                            <ProjectModelListEntry
                                key={model.id}
                                model={model}
                                lastItem={index === models.length - 1}
                            />
                        ))}
                    </List>
                </Box>
            </Collapse>
        </Box>
    );
};

const ProjectModelListEntry = ({
    model,
    lastItem,
}: {
    model: GtmModelUnion;
    lastItem?: boolean;
}) => {
    const [isHovering, setIsHovering] = useState(false);
    const { navigateToModelUrl } = useGtmNavigator();
    const ModelIcon = modelTypeToIconMap.get(model.type);

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    const handleOnClick = () => {
        navigateToModelUrl(model.id);
    };

    return (
        <ListItem
            disableGutters
            disablePadding
            divider={!lastItem}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
        >
            <ListItemButton
                onClick={handleOnClick}
                sx={(theme) => ({ padding: theme.spacing(0, 0, 0, 2) })}
            >
                {ModelIcon && <ModelIcon sx={{ mr: 1 }} />}
                <ListItemText
                    disableTypography
                    primary={
                        <OverflowTooltipTypography variant="body2">
                            {model.name}
                        </OverflowTooltipTypography>
                    }
                />
                <Box p={1}>
                    {isHovering ? (
                        <ArrowForwardIcon color="primary" sx={{ fontSize: 20 }} />
                    ) : (
                        <Box sx={{ height: '25px' }} />
                    )}
                </Box>
            </ListItemButton>
        </ListItem>
    );
};

const modelTypeToIconMap = new Map([
    [GtmModelType.Analytical, TriangulatedIcon],
    [GtmModelType.CrossSection, CrossSectionIcon],
    [GtmModelType.ParametrizedGeometry, ParametricIcon],
]);
