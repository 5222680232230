import { useMemo, useState } from 'react';

import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import {
    DESIGN_GEOMETRY_SCHEMA,
    GTM_FOLDER_PREFIX,
    PARAMETERIZED_VOLUMES_NAME,
} from 'src/constants';
import {
    useTransformationManager,
    ShouldRenderUpdatedObjects,
    ShouldRunDetectorsOnUpdatedObjects,
} from 'src/hooks/transformation/useTransformationManager';
import { clearParameterizedVolumes, setParameterizedVolumes } from 'src/store/project/projectSlice';
import {
    makeSelectIsObjectOutdatedOrMissing,
    selectProjectSettings,
} from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector, useGetLatestState } from 'src/store/store';
import type { ObjectIdWithVersion } from 'src/types/core.types';
import { decorateNewObject } from 'src/utils/decorateObject';
import { assert } from 'src/utils/gtmAssert';

interface ParameterizationError {
    type: string;
    failed_volumes: Array<[number, string]>;
}

export function useParameterizedVolumesManager() {
    const dispatch = useAppDispatch();
    const {
        executeTransformation,
        transformationStatus: parameterizationStatus,
        resetTransformationManagerState,
    } = useTransformationManager();
    const projectSettings = useAppSelector(selectProjectSettings);
    assert(projectSettings !== undefined, 'Current project settings are not defined');
    const memoizedSelectIsObjectOutdatedOrMissing = useMemo(
        makeSelectIsObjectOutdatedOrMissing,
        [],
    );
    const getLatestState = useGetLatestState();
    const [failedVolumeIndices, setFailedVolumeIndices] = useState<number[]>([]);

    function handleParameterizationError(error: any) {
        try {
            const message = error as ParameterizationError;
            const failedVolumes = message.failed_volumes?.map(([index, _]) => index) ?? [];
            setFailedVolumeIndices(failedVolumes);
        } catch {
            throw error;
        }
    }

    async function computeParameterizedVolumes(aggregateGeometry: ObjectIdWithVersion) {
        resetTransformationManagerState();
        setFailedVolumeIndices([]);

        try {
            await executeTransformation(
                GtmMeshTransformationAction.ParameterizeVolumes,
                ShouldRenderUpdatedObjects.No,
                ShouldRunDetectorsOnUpdatedObjects.No,
                [aggregateGeometry],
                {
                    name: `${GTM_FOLDER_PREFIX}/${aggregateGeometry.id}/parameterizedVolumes`,
                    distanceUnit: projectSettings!.units,
                },
                {
                    createdObjectsHandler: (createdObjects) => {
                        dispatch(
                            setParameterizedVolumes(
                                decorateNewObject(
                                    createdObjects[0],
                                    PARAMETERIZED_VOLUMES_NAME,
                                    DESIGN_GEOMETRY_SCHEMA,
                                    false,
                                ),
                            ),
                        );
                    },
                    cancellationPredicate: () =>
                        getLatestState((state) =>
                            memoizedSelectIsObjectOutdatedOrMissing(state, aggregateGeometry),
                        ),
                },
                true,
            );
        } catch (error) {
            handleParameterizationError(error);
        }
    }

    function resetParameterizedVolumes() {
        dispatch(clearParameterizedVolumes());
    }

    return {
        computeParameterizedVolumes,
        failedVolumeIndices,
        parameterizationStatus,
        resetParameterizedVolumes,
    };
}
