import type { GtmModelUnion } from 'src/gtmProject';

export const CROSS_SECTIONS_LABEL = 'Cross Sections';
export const ADD_CROSS_SECTION_LABEL = 'Add Cross Section';
export const getRemoveCrossSectionDescription = (model: GtmModelUnion, crossSectionName: string) =>
    `Remove cross section "${crossSectionName}" from model "${model.name}"`;
export const DELETE_CROSS_SECTION_LABEL = 'Delete cross section';
export const PUBLISH_CROSS_SECTION_LABEL = 'Publish cross section';
export const getDeleteCrossSectionMessage = (crossSectionName: string) =>
    `Are you sure you want to delete "${crossSectionName}" from this model?`;
