import { createContext, useContext, useMemo } from 'react';

import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import type { ExecuteTransformationType } from 'src/hooks/transformation/useTransformationManager';
import {
    TransformationStatus,
    useTransformationManager,
} from 'src/hooks/transformation/useTransformationManager';
import {
    AGGREGATING_GEOM_MESSAGE,
    AGGREGATION_FAILURE_MESSAGE,
    AGGREATION_SUCCESS_MESSAGE,
    FILLING_HOLES_FAILURE_MESSAGE,
    FILLING_HOLES_MESSAGE,
    FILLING_HOLES_SUCCESS_MESSAGE,
    FIX_SELF_INTERSECTIONS_FAILURE_MESSAGE,
    FIX_SELF_INTERSECTIONS_MESSAGE,
    FIX_SELF_INTERSECTIONS_SUCCESS_MESSAGE,
    FIXING_DEGENERATE_TRIANGLES_FAILURE_MESSAGE,
    FIXING_DEGENERATE_TRIANGLES_MESSAGE,
    FIXING_DEGENERATE_TRIANGLES_SUCCESS_MESSAGE,
    REMESH_FAILURE_MESSAGE,
    REMESH_MESSAGE,
    REMESH_SUCCESS_MESSAGE,
    REMOVING_VOLUME_MESSAGE,
    RESET_AGGREGATE_FAILURE_MESSAGE,
    RESET_AGGREGATE_GEOM_MESSAGE,
    RESET_AGGREGATE_SUCCESS_MESSAGE,
    VOLUME_REMOVAL_FAILURE_MESSAGE,
    VOLUME_REMOVAL_SUCCESS_MESSAGE,
} from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/IssuesTab/TransformationAction/TransformationAction.constants';
import { TransformationProgressModal } from 'src/visualization/TransformationProgressModal/TransformationProgressModal';

const fixSelfIntersectionsTitlesByStatus = new Map<TransformationStatus, string>([
    [TransformationStatus.Transforming, FIX_SELF_INTERSECTIONS_MESSAGE],
    [TransformationStatus.Uploading, FIX_SELF_INTERSECTIONS_MESSAGE],
    [TransformationStatus.Complete, FIX_SELF_INTERSECTIONS_SUCCESS_MESSAGE],
    [TransformationStatus.Failed, FIX_SELF_INTERSECTIONS_FAILURE_MESSAGE],
]);

const fillAllHolesTitlesByStatus = new Map<TransformationStatus, string>([
    [TransformationStatus.Transforming, FILLING_HOLES_MESSAGE],
    [TransformationStatus.Uploading, FILLING_HOLES_MESSAGE],
    [TransformationStatus.Complete, FILLING_HOLES_SUCCESS_MESSAGE],
    [TransformationStatus.Failed, FILLING_HOLES_FAILURE_MESSAGE],
]);

const resetAggregateTitlesByStatus = new Map<TransformationStatus, string>([
    [TransformationStatus.Transforming, RESET_AGGREGATE_GEOM_MESSAGE],
    [TransformationStatus.Uploading, RESET_AGGREGATE_GEOM_MESSAGE],
    [TransformationStatus.Complete, RESET_AGGREGATE_SUCCESS_MESSAGE],
    [TransformationStatus.Failed, RESET_AGGREGATE_FAILURE_MESSAGE],
]);

const addToAggregateTitlesByStatus = new Map<TransformationStatus, string>([
    [TransformationStatus.Transforming, AGGREGATING_GEOM_MESSAGE],
    [TransformationStatus.Uploading, AGGREGATING_GEOM_MESSAGE],
    [TransformationStatus.Complete, AGGREATION_SUCCESS_MESSAGE],
    [TransformationStatus.Failed, AGGREGATION_FAILURE_MESSAGE],
]);

const fixDegenerateTrianglesTitlesByStatus = new Map<TransformationStatus, string>([
    [TransformationStatus.Transforming, FIXING_DEGENERATE_TRIANGLES_MESSAGE],
    [TransformationStatus.Uploading, FIXING_DEGENERATE_TRIANGLES_MESSAGE],
    [TransformationStatus.Complete, FIXING_DEGENERATE_TRIANGLES_SUCCESS_MESSAGE],
    [TransformationStatus.Failed, FIXING_DEGENERATE_TRIANGLES_FAILURE_MESSAGE],
]);

const subsumeVolumeTitlesByStatus = new Map<TransformationStatus, string>([
    [TransformationStatus.Transforming, REMOVING_VOLUME_MESSAGE],
    [TransformationStatus.Uploading, REMOVING_VOLUME_MESSAGE],
    [TransformationStatus.Complete, VOLUME_REMOVAL_SUCCESS_MESSAGE],
    [TransformationStatus.Failed, VOLUME_REMOVAL_FAILURE_MESSAGE],
]);

const remeshTitlesByStatus = new Map<TransformationStatus, string>([
    [TransformationStatus.Transforming, REMESH_MESSAGE],
    [TransformationStatus.Uploading, REMESH_MESSAGE],
    [TransformationStatus.Complete, REMESH_SUCCESS_MESSAGE],
    [TransformationStatus.Failed, REMESH_FAILURE_MESSAGE],
]);

const percentagesByStatus = new Map<TransformationStatus, number>([
    [TransformationStatus.Transforming, 0],
    [TransformationStatus.Uploading, 70],
    [TransformationStatus.Complete, 100],
    [TransformationStatus.Failed, 100],
]);

function getTitlesByStatus(transformationAction: GtmMeshTransformationAction | undefined) {
    switch (transformationAction) {
        case GtmMeshTransformationAction.FillHoles:
            return fillAllHolesTitlesByStatus;
        case GtmMeshTransformationAction.Intersect:
            return fixSelfIntersectionsTitlesByStatus;
        case GtmMeshTransformationAction.InitAggregateGeom:
            return resetAggregateTitlesByStatus;
        case GtmMeshTransformationAction.AddToAggregateGeom:
            return addToAggregateTitlesByStatus;
        case GtmMeshTransformationAction.LocalRemesh:
            return fixDegenerateTrianglesTitlesByStatus;
        case GtmMeshTransformationAction.SubsumeVolumeInAggregate:
            return subsumeVolumeTitlesByStatus;
        case GtmMeshTransformationAction.Remesh:
            return remeshTitlesByStatus;
        default:
            return undefined;
    }
}

type ModalTransformationContextProps = {
    executeTransformation: ExecuteTransformationType;
};

type ModalTransformationContextProvider = {
    children: React.ReactNode;
};

/**
 * Context that allows passing the `executeTransformation` function of a single
 * `useTransformationManager` instance down the tree, while rendering a modal with the
 * transformation status.
 */
export const ModalTransformationContext = createContext<
    ModalTransformationContextProps | undefined
>(undefined);

/**
 * Provider of `ModalTransformationContext`.
 * To use, wrap the components that need to execute transformations with progress modals in this
 * provider. Make sure that the provider is placed in a component that outlives the transformation.
 * If it doesn't, bubble it up in the tree hierarchy.
 */
export const ModalTransformationProvider = ({ children }: ModalTransformationContextProvider) => {
    const {
        executeTransformation,
        resetTransformationManagerState,
        transformationStatus,
        currentTransformationAction,
    } = useTransformationManager();

    const providedValue = useMemo(() => ({ executeTransformation }), []);
    const titlesByStatus = getTitlesByStatus(currentTransformationAction);

    return (
        <ModalTransformationContext.Provider value={providedValue}>
            {children}
            {titlesByStatus && (
                <TransformationProgressModal
                    transformationStatus={transformationStatus}
                    transformationTitles={titlesByStatus}
                    transformationPercentages={percentagesByStatus}
                    resetStatus={resetTransformationManagerState}
                />
            )}
        </ModalTransformationContext.Provider>
    );
};

/**
 * Hook to access the `executeTransformation` function of a single `useTransformationManager`
 * instance.
 */
export function useModalTransformation() {
    const context = useContext(ModalTransformationContext);
    if (!context) {
        throw new Error('useModalTransformation must be used within a ModalTransformationProvider');
    }

    return context;
}
