export const getFillAllHolesLabel = (numOfHoles: number) => `Fill all holes (${numOfHoles})`;
export const FILLING_HOLES_MESSAGE = 'Filling holes...';
export const FILLING_HOLES_SUCCESS_MESSAGE = 'Holes filled successfully';
export const FILLING_HOLES_FAILURE_MESSAGE = 'Failed to fill holes';
export const FILL_LABEL = 'Fill';
export const FILL_AND_REFINE_LABEL = 'Fill and refine';
export const FILL_INFO = 'Fill all holes.';
export const FILL_AND_REFINE_INFO =
    'Will try to make the fill C1 continuous with the hole boundary.';

export const FIX_DEGENERATE_TRIANGLES_LABEL = 'Fix Degenerate Triangles';
export const FIXING_DEGENERATE_TRIANGLES_MESSAGE = 'Fixing degenerate triangles...';
export const FIXING_DEGENERATE_TRIANGLES_SUCCESS_MESSAGE =
    'Degenerate triangles fixed successfully';
export const FIXING_DEGENERATE_TRIANGLES_FAILURE_MESSAGE = 'Failed to fix degenerate triangles';

export const FIX_SELF_INTERSECTIONS_LABEL = 'Fix Self-Intersections';
export const FIX_SELF_INTERSECTIONS_MESSAGE = 'Fixing self-intersections...';
export const FIX_SELF_INTERSECTIONS_SUCCESS_MESSAGE = 'Self-intersections fixed successfully';
export const FIX_SELF_INTERSECTIONS_FAILURE_MESSAGE = 'Failed to fix self-intersections';

export const RESET_AGGREGATE_GEOM_MESSAGE = `Resetting aggregate geometry...`;
export const RESET_AGGREGATE_SUCCESS_MESSAGE = 'Aggregate geometry reset successfully';
export const RESET_AGGREGATE_FAILURE_MESSAGE = 'Failed to reset aggregate geometry';

export const AGGREGATING_GEOM_MESSAGE = `Aggregating geometry...`;
export const AGGREATION_SUCCESS_MESSAGE = 'Aggregation successful';
export const AGGREGATION_FAILURE_MESSAGE = 'Aggregation failed';

export const REMOVING_VOLUME_MESSAGE = `Removing volume...`;
export const VOLUME_REMOVAL_SUCCESS_MESSAGE = 'Volume removal successful';
export const VOLUME_REMOVAL_FAILURE_MESSAGE = 'Volume removal failed';

export const REMESH_MESSAGE = 'Remeshing...';
export const REMESH_SUCCESS_MESSAGE = 'Remeshing complete';
export const REMESH_FAILURE_MESSAGE = 'Failed to remesh';
