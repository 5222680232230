import { useTrace } from '@local/web-design-system-2';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import {
    GtmMeshDetectorAction,
    GtmMeshFillMode,
    GtmMeshTransformationAction,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { FillAndRefineHolesIcon } from 'src/assets/FillAndRefineHolesIcon';
import { FillHolesIcon } from 'src/assets/FillHoleIcon';
import { useParameterizedVolumesManager } from 'src/hooks/modelling/useParameterizedVolumesManager';
import { useVolumesManager } from 'src/hooks/modelling/useVolumesManager';
import { useObjectManager } from 'src/hooks/project/useObjectManager';
import { useModalTransformation } from 'src/hooks/transformation/useModalTransformation';
import {
    ShouldRenderUpdatedObjects,
    ShouldRunDetectorsOnUpdatedObjects,
} from 'src/hooks/transformation/useTransformationManager';
import { issueDataForObjectAndAction } from 'src/store/issues/selectors';
import { selectCurrentModelSelectedObject } from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { assert } from 'src/utils/gtmAssert';
import {
    FILL_AND_REFINE_LABEL,
    FILL_LABEL,
} from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/IssuesTab/TransformationAction/TransformationAction.constants';

interface FillSelectedHoleProps {
    holeIndex: number;
}

export const FillSelectedHole = ({ holeIndex }: FillSelectedHoleProps) => {
    const applyTrace = useTrace('fill-selected-hole');
    const { executeTransformation } = useModalTransformation();
    const selectedObject = useAppSelector(selectCurrentModelSelectedObject);
    assert(
        selectedObject !== undefined,
        'Filling selected hole does not know to which object it applies.',
    );
    const selectedObjectHoles = useAppSelector(
        issueDataForObjectAndAction(selectedObject!.id, GtmMeshDetectorAction.DetectHoles),
    );
    const { resetVolumes } = useVolumesManager();
    const { resetParameterizedVolumes } = useParameterizedVolumesManager();
    const { isAggregate } = useObjectManager();

    const handleTransformation = (fillMode: GtmMeshFillMode) => {
        if (!selectedObjectHoles || !selectedObject) {
            return;
        }

        const params = { fillMode, edges: selectedObjectHoles[holeIndex].edges[0] };
        executeTransformation(
            GtmMeshTransformationAction.FillHoles,
            ShouldRenderUpdatedObjects.Yes,
            ShouldRunDetectorsOnUpdatedObjects.Yes,
            [selectedObject],
            params,
            {
                handleAdditionalSideEffects: () => {
                    if (isAggregate(selectedObject.id)) {
                        resetVolumes();
                        resetParameterizedVolumes();
                    }
                },
            },
        );
    };

    const handleFillClick = () => {
        // Default fill is "fill and refine" mode as per design
        handleTransformation(GtmMeshFillMode.FillAndRefine);
    };

    const handleFillAndRefineClick = () => {
        // Refined fill is "fill, refine and fair" mode as per design
        handleTransformation(GtmMeshFillMode.FillRefineAndFair);
    };

    return (
        <Stack direction="row">
            <Tooltip title={FILL_LABEL}>
                <IconButton
                    size="small"
                    onClick={handleFillClick}
                    automation-id={applyTrace(`fill-button-${holeIndex}`)}
                >
                    <FillHolesIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Tooltip title={FILL_AND_REFINE_LABEL}>
                <IconButton
                    size="small"
                    onClick={handleFillAndRefineClick}
                    automation-id={applyTrace(`fill-and-refine-button-${holeIndex}`)}
                >
                    <FillAndRefineHolesIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Stack>
    );
};
