import { Mutex } from 'async-mutex';

import { injectedFileClient } from 'src/apiClients/file/customFileEndpoints';
import type { GtmHistoryOperation } from 'src/gtmProject';
import { createFileFromProject } from 'src/hooks/utils';
import {
    appendHistoryEntry,
    clearRedoEntries,
    endProjectSync,
    setCurrentProjectVersionId,
    startProjectSync,
} from 'src/store/project/projectSlice';
import {
    selectCurrentProjectData,
    selectCurrentProjectVersionId,
} from 'src/store/project/selectors';
import type { AppThunk } from 'src/store/store';
import { getOrgUuidAndWorkSpaceUuid } from 'src/store/thunks/utils';
import { makeHistoryEntry } from 'src/utils/history/history';

const syncProjectMutex = new Mutex();

export const skipHistoryEntry = Symbol('useProjectSynchronizer/skipHistoryEntry');
export type SkipHistoryEntry = typeof skipHistoryEntry;

export const syncProjectThunk =
    (gtmHistoryOperation: GtmHistoryOperation | SkipHistoryEntry): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
        await syncProjectMutex.acquire();
        dispatch(startProjectSync());

        const currentProjectVersion = selectCurrentProjectVersionId(getState());

        if (gtmHistoryOperation !== skipHistoryEntry) {
            dispatch(
                appendHistoryEntry(makeHistoryEntry(gtmHistoryOperation, currentProjectVersion)),
            );
            dispatch(clearRedoEntries());
        }

        const currentProject = selectCurrentProjectData(getState());
        const updatedFile = createFileFromProject(currentProject);
        const { orgUuid, workspaceUuid } = getOrgUuidAndWorkSpaceUuid();

        try {
            const response: { data?: { version_id: string } } = await dispatch(
                injectedFileClient.endpoints.customUpsertFileByPathNoPolling.initiate({
                    organisationId: orgUuid,
                    workspaceId: workspaceUuid,
                    filePath: updatedFile.name,
                    uploadFile: updatedFile,
                }),
            );

            if (!response.data) {
                throw new Error('No data returned from upload');
            }
            dispatch(setCurrentProjectVersionId(response.data.version_id));
        } finally {
            dispatch(endProjectSync());
            syncProjectMutex.release();
        }
    };
