import { Evo as EvoIcon, useTrace } from '@local/web-design-system-2';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

import { CrossSectionIcon } from 'src/assets/CrossSectionIcon';
import { OverflowTooltipTypography } from 'src/components/OverflowTooltipTypography';
import type { CrossSection } from 'src/gtmProject';
import { useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import {
    deselectCrossSection,
    removeCrossSectionFromCurrentModel,
    selectCrossSection,
} from 'src/store/project/projectSlice';
import {
    selectCurrentModel,
    selectCurrentModelCrossSections,
    selectSelectedCrossSectionIndex,
} from 'src/store/project/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { setShouldShowProjectPanel } from 'src/store/ui/projectPanel';
import {
    resetCrossSectionPanelMode,
    switchToCrossSectionPanelDrawLineMode,
    switchToCrossSectionPanelViewPropertiesMode,
} from 'src/store/ui/settingsPanel';
import { DEFAULT_LIST_MAX_HEIGHT } from 'src/styles';
import { fileNameExtensionRemover } from 'src/utils';
import { PublishCrossSectionToEvoModal } from 'src/visualization/CrossSectionPanel/PublishCrossSectionToEvoModal';
import { DeleteDialog } from 'src/visualization/ProjectPanel/components/DeleteDialog';
import {
    ADD_CROSS_SECTION_LABEL,
    CROSS_SECTIONS_LABEL,
    DELETE_CROSS_SECTION_LABEL,
    getDeleteCrossSectionMessage,
    getRemoveCrossSectionDescription,
    PUBLISH_CROSS_SECTION_LABEL,
} from 'src/visualization/ProjectPanel/components/ModelView/ModelViewConstants';

export const CrossSections = () => {
    const applyTrace = useTrace('cross-sections');
    const dispatch = useAppDispatch();
    const crossSections = useAppSelector(selectCurrentModelCrossSections);
    const { clearSlice } = useBaseXyz();

    const handleAddCrossSection = () => {
        dispatch(setShouldShowProjectPanel(false));
        dispatch(switchToCrossSectionPanelDrawLineMode());
        dispatch(deselectCrossSection());
        clearSlice();
    };

    return (
        <Stack p={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1} alignItems="center">
                    <CrossSectionIcon fontSize="small" />
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ textTransform: 'uppercase' }}
                    >
                        {CROSS_SECTIONS_LABEL}
                    </Typography>
                </Stack>
                <Tooltip title={ADD_CROSS_SECTION_LABEL}>
                    <IconButton
                        automation-id={applyTrace('add-cross-section-button')}
                        size="small"
                        onClick={handleAddCrossSection}
                    >
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
            {crossSections.length > 0 ? (
                <Box mt={2} mb={2}>
                    <Box
                        sx={(theme) => ({
                            maxHeight: DEFAULT_LIST_MAX_HEIGHT,
                            overflowY: 'auto',
                            borderRadius: theme.spacing(0.5),
                            border: 1,
                            borderColor: 'divider',
                        })}
                    >
                        <List dense disablePadding>
                            {crossSections.map((object, index) => (
                                <CrossSectionListItem
                                    index={index}
                                    key={object.id}
                                    object={object}
                                    isLastItem={index === crossSections.length - 1}
                                />
                            ))}
                        </List>
                    </Box>
                </Box>
            ) : null}
        </Stack>
    );
};

const CrossSectionListItem = ({
    index,
    object,
    isLastItem,
}: Readonly<{
    index: number;
    object: CrossSection;
    isLastItem?: boolean;
}>) => {
    const applyTrace = useTrace(`cross-section-list-item-${index}`);
    const dispatch = useAppDispatch();
    const { syncProject } = useProjectSynchronizer();
    const currentModel = useAppSelector(selectCurrentModel);
    const selectedCrossSectionIndex = useAppSelector(selectSelectedCrossSectionIndex);
    const [isHovering, setIsHovering] = useState(false);
    const isSelected = index === selectedCrossSectionIndex;
    const [shouldOpenDeleteDialog, setShouldOpenDeleteDialog] = useState(false);
    const [shouldOpenPublishDialog, setShouldOpenPublishDialog] = useState(false);

    const handleCrossSectionClick = () => {
        if (isSelected) {
            dispatch(deselectCrossSection());
            dispatch(resetCrossSectionPanelMode());
        } else {
            dispatch(selectCrossSection(index));
            dispatch(switchToCrossSectionPanelViewPropertiesMode());
        }
    };

    const handleDeleteConfirm = () => {
        dispatch(removeCrossSectionFromCurrentModel(object));
        dispatch(resetCrossSectionPanelMode());
        dispatch(deselectCrossSection());
        setShouldOpenDeleteDialog(false);
        syncProject({
            description: getRemoveCrossSectionDescription(currentModel!, object.name),
        });
    };

    return (
        <>
            <ListItem
                disableGutters
                disablePadding
                divider={!isLastItem}
                onMouseEnter={() => {
                    setIsHovering(true);
                }}
                onMouseLeave={() => {
                    setIsHovering(false);
                }}
                sx={{ height: '42px' }}
            >
                <ListItemButton
                    selected={isSelected}
                    sx={(theme) => ({ padding: theme.spacing(0.5, 1) })}
                    onClick={handleCrossSectionClick}
                >
                    <ListItemText
                        disableTypography
                        primary={
                            <Stack direction="row" spacing={1.25} paddingRight="5px">
                                <OverflowTooltipTypography variant="body2">
                                    {fileNameExtensionRemover(object.name)}
                                </OverflowTooltipTypography>
                            </Stack>
                        }
                    />
                    {isHovering || isSelected ? (
                        <Stack direction="row">
                            <Tooltip title={PUBLISH_CROSS_SECTION_LABEL}>
                                <IconButton
                                    automation-id={applyTrace('publish-button')}
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShouldOpenPublishDialog(true);
                                    }}
                                >
                                    <EvoIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={DELETE_CROSS_SECTION_LABEL}>
                                <IconButton
                                    automation-id={applyTrace('delete-button')}
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShouldOpenDeleteDialog(true);
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    ) : null}
                </ListItemButton>
            </ListItem>
            <PublishCrossSectionToEvoModal
                shouldOpenConfirmDialog={shouldOpenPublishDialog}
                closeConfirmDialog={() => {
                    setShouldOpenPublishDialog(false);
                }}
                crossSection={object}
            />
            <DeleteDialog
                title={DELETE_CROSS_SECTION_LABEL}
                shouldOpen={shouldOpenDeleteDialog}
                message={getDeleteCrossSectionMessage(object.name)}
                handleCancel={() => {
                    setShouldOpenDeleteDialog(false);
                }}
                handleDelete={handleDeleteConfirm}
            />
        </>
    );
};
