import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { v4 as uuidv4 } from 'uuid';

import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { DisaggregateIcon } from 'src/assets/DisaggregateIcon';
import { AGGREGATE_GEOMETRY_NAME, MESH_SCHEMA } from 'src/constants';
import { useConglomerateActionManager } from 'src/hooks/conglomerate/useConglomerateActionManager';
import { useParameterizedVolumesManager } from 'src/hooks/modelling/useParameterizedVolumesManager';
import { useVolumesManager } from 'src/hooks/modelling/useVolumesManager';
import { useModalTransformation } from 'src/hooks/transformation/useModalTransformation';
import {
    shouldRenderObject,
    ShouldRunDetectorsOnUpdatedObjects,
} from 'src/hooks/transformation/useTransformationManager';
import { disaggregateAllObjects, setAggregate } from 'src/store/project/projectSlice';
import { selectCurrentBounds, selectCurrentAggregateGeometry } from 'src/store/project/selectors';
import { useAppSelector, useAppDispatch } from 'src/store/store';
import type { ObjectId } from 'src/types/core.types';
import { decorateNewObject } from 'src/utils/decorateObject';

export function ResetAggregateControl() {
    const applyTrace = useTrace('reset-aggregate-control');
    const currentBounds = useAppSelector(selectCurrentBounds);
    const currentAggregateGeometry = useAppSelector(selectCurrentAggregateGeometry);
    const dispatch = useAppDispatch();

    const { clearVisualizationAndIssuesForObject } = useConglomerateActionManager();
    const { resetVolumes } = useVolumesManager();
    const { resetParameterizedVolumes } = useParameterizedVolumesManager();
    const { executeTransformation } = useModalTransformation();

    async function performReset(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation();
        await executeTransformation(
            GtmMeshTransformationAction.InitAggregateGeom,
            shouldRenderObject(currentAggregateGeometry!),
            ShouldRunDetectorsOnUpdatedObjects.Yes,
            [],
            {
                ...currentBounds!,
                boundaryId: uuidv4() as ObjectId,
            },
            {
                createdObjectsHandler: (createdObjects) =>
                    dispatch(
                        setAggregate(
                            decorateNewObject(
                                createdObjects[0],
                                AGGREGATE_GEOMETRY_NAME,
                                MESH_SCHEMA,
                                currentAggregateGeometry?.visible ?? true,
                            ),
                        ),
                    ),
                handleAdditionalSideEffects: () => {
                    resetVolumes();
                    resetParameterizedVolumes();
                    clearVisualizationAndIssuesForObject(currentAggregateGeometry!);
                    dispatch(disaggregateAllObjects());
                },
            },
        );
    }

    return (
        <Tooltip title="Reset Aggregate" placement="bottom" enterDelay={0}>
            <IconButton
                onClick={performReset}
                edge="end"
                size="small"
                automation-id={applyTrace('disaggregate-button')}
            >
                <DisaggregateIcon fontSize="inherit" />
            </IconButton>
        </Tooltip>
    );
}
