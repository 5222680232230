import { getCurrentEvoInstance } from '@local/login';
import { getCombinedToken as getAccessToken } from '@local/login/dist/store/sessionStorageHelpers/accessTokenHelper/accessTokenHelper';

import {
    TASK_TOPIC,
    TASK_DEV_BASE_URL,
    TASK_POLLING_TIMEOUT_MS,
    TASK_POLLING_INTERVAL_BASE_MS,
    TASK_POLLING_INTERVAL_MULT,
    TASK_POLLING_INTERVAL_EXP,
    ENVIRONMENT,
    ENVIRONMENT_TEST,
} from 'src/constants';

import { waitForMs } from '../file/utils';
import type { ComputeTaskQuery, TaskResult, TaskStatusResponse } from './computeApi.types';
import { JobStatus } from './computeApi.types';

const apiHeaders = () => {
    const token = getAccessToken()?.access_token;
    return {
        'Content-Type': 'application/json',
        'API-Preview': 'opt-in',
        Authorization: `Bearer ${token}`,
    };
};

const pollTaskCompletion = async (url: string) => {
    /* eslint-disable no-await-in-loop */
    const statusUrl = `${url}/status`;
    const startTimeMs = Date.now();
    while (Date.now() - startTimeMs < TASK_POLLING_TIMEOUT_MS) {
        const waitTimeMs =
            TASK_POLLING_INTERVAL_BASE_MS +
            (TASK_POLLING_INTERVAL_MULT * (Date.now() - startTimeMs)) ** TASK_POLLING_INTERVAL_EXP;
        await waitForMs(waitTimeMs);

        const response = await fetch(statusUrl, {
            method: 'GET',
            headers: apiHeaders(),
        });
        // The task API returns 202 if we need to keep polling.
        // 200 means we are good to go.
        if (response.status !== 200 && response.status !== 202) {
            return { error: await response.json() };
        }
        const taskInitResult: TaskStatusResponse = await response.json();

        if (
            taskInitResult.status !== JobStatus.InProgress &&
            taskInitResult.status !== JobStatus.Cancelling &&
            taskInitResult.status !== JobStatus.Requested
        ) {
            return taskInitResult;
        }
    }
    /* eslint-enable no-await-in-loop */

    return {
        job_status: { status: JobStatus.Failed, error: 'Task timed out' },
    };
};

function taskBaseUrl() {
    const evoHub = getCurrentEvoInstance()?.hub?.url;
    if (ENVIRONMENT === ENVIRONMENT_TEST || !evoHub) {
        return TASK_DEV_BASE_URL;
    }
    return evoHub;
}

export const computeAsyncTask = async (taskName: string, query: ComputeTaskQuery<any>) => {
    const baseUrl = taskBaseUrl();
    const taskInitUrl = `${baseUrl}/compute/orgs/${query.orgId}/${TASK_TOPIC}/${taskName}/`;

    // Request the async task start
    const taskInitResultReq = await fetch(taskInitUrl, {
        method: 'POST',
        headers: apiHeaders(),
        body: JSON.stringify({ parameters: query.parameters }),
    });
    if (taskInitResultReq.status !== 202 && taskInitResultReq.status !== 200) {
        return { error: await taskInitResultReq.json() };
    }
    const taskInitResult: TaskStatusResponse = await taskInitResultReq.json();

    const taskUrl = `${baseUrl}${taskInitResult.links.cancel || taskInitResult.links.result}`;
    await pollTaskCompletion(taskUrl);

    // Get the task result
    const res = await fetch(taskUrl, {
        method: 'GET',
        headers: apiHeaders(),
    });
    if (res.status !== 200) {
        return { error: await res.json() };
    }
    const taskResult: TaskResult = await res.json();
    if (taskResult.error) {
        return { error: taskResult.error };
    }
    return { data: taskResult.results };
};
