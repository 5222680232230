import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import { useBaseXyz } from '@local/webviz/dist/context';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import type { SxProps, Theme } from '@mui/material/styles';

import { VolumeIcon } from 'src/assets/VolumeIcon';
import { OverflowTooltipTypography } from 'src/components/OverflowTooltipTypography';
import {
    type AggregatableObject,
    type GtmEvoOutputObject,
    type GtmProjectInput,
} from 'src/gtmProject';
import type { GtmParametrizedGeometryModel } from 'src/gtmProject';
import { useSceneObjectDataManager } from 'src/hooks';
import { useHighlightSceneObject } from 'src/hooks/useHighlightSceneObject';
import { DEFAULT_LIST_MAX_HEIGHT } from 'src/styles';
import { fileNameExtensionRemover } from 'src/utils';
import {
    HideShowButtons,
    ObjectType,
} from 'src/visualization/ProjectPanel/components/HideShowButtons';
import { CrossSections } from 'src/visualization/ProjectPanel/components/ModelView/CrossSections';

interface ParametrizedGeometryModelViewProps {
    model: GtmParametrizedGeometryModel;
}

export const ParametrizedGeometryModelView = ({
    model,
}: Readonly<ParametrizedGeometryModelViewProps>) => {
    const applyTrace = useTrace('parametrized-geometry-model-view');

    return (
        <Box automation-id={applyTrace()}>
            <ParametricObjectsList volumes={model.volumes} />
            <Divider />
            <CrossSections />
        </Box>
    );
};

function ParametricObjectsList({ volumes }: { volumes: GtmEvoOutputObject[] }) {
    return (
        <Box p={2}>
            <Box
                sx={(theme) => ({
                    maxHeight: DEFAULT_LIST_MAX_HEIGHT,
                    overflowY: 'auto',
                    borderRadius: theme.spacing(0.5),
                    border: 1,
                    borderColor: 'divider',
                })}
            >
                <List dense disablePadding>
                    {volumes.map((object, index) => (
                        <VolumeListItem
                            objectType={ObjectType.Volume}
                            key={object.id}
                            object={object}
                            isLastItem={index === volumes.length - 1}
                        />
                    ))}
                </List>
            </Box>
        </Box>
    );
}

function VolumeListItem({
    object,
    objectType,
    isLastItem,
}: Readonly<{
    object: GtmProjectInput | AggregatableObject;
    objectType: ObjectType;
    isLastItem?: boolean;
}>) {
    const { isObjectOnPlotByObjectId } = useSceneObjectDataManager();
    const { getZoomToViewTool } = useBaseXyz();
    const { highlightSurface, removeHighlight } = useHighlightSceneObject(object.id);

    const handleOnClick = () => {
        if (isObjectOnPlotByObjectId(object.id)) {
            getZoomToViewTool().zoomToView(object.id);
        }
    };

    return (
        <ListItem disableGutters disablePadding divider={!isLastItem}>
            <ListItemButton
                sx={(theme) => ({ padding: theme.spacing(0.5, 1) })}
                onClick={handleOnClick}
            >
                <HideShowButtons object={object} objectType={objectType} />
                <Divider
                    flexItem
                    orientation="vertical"
                    sx={{ height: '16px', margin: 'auto 0' }}
                />
                <Stack
                    onMouseLeave={removeHighlight}
                    onMouseEnter={highlightSurface}
                    direction="row"
                    width="100%"
                    alignItems="center"
                >
                    <VolumeIcon fontSize="small" sx={{ marginLeft: 1 }} />
                    <ModelObjectName sx={{ paddingLeft: 1 }} object={object} />
                </Stack>
            </ListItemButton>
        </ListItem>
    );
}

function ModelObjectName({
    object,
    sx = [],
}: Readonly<{ object: GtmEvoOutputObject | GtmProjectInput; sx?: SxProps<Theme> }>) {
    return (
        <ListItemText
            disableTypography
            primary={
                <OverflowTooltipTypography variant="body2" sx={sx}>
                    {fileNameExtensionRemover(object.name)}
                </OverflowTooltipTypography>
            }
        />
    );
}
