import { WDSThemeProvider } from '@local/web-design-system-2';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { useContext } from 'react';

import { WDS2ThemeContext } from 'src/context/ThemeContext/ThemeContext';
import { ModalTransformationProvider } from 'src/hooks/transformation/useModalTransformation';
import { selectCurrentModel } from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { CameraTools } from 'src/visualization/Toolbar/CameraTools';
import { UndoRedoActions } from 'src/visualization/Toolbar/UndoRedoActions';

import { RemeshButton } from './RemeshButton';

export const Toolbar = () => {
    const { theme: appTheme } = useContext(WDS2ThemeContext);
    const currentModel = useAppSelector(selectCurrentModel);
    return (
        <Box
            sx={{
                position: 'absolute',
                top: '33px',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1,
            }}
        >
            <WDSThemeProvider themeMode={appTheme}>
                <Paper elevation={4}>
                    <Stack
                        sx={{ alignItems: 'center', paddingLeft: 1, paddingRight: 1 }}
                        direction="row"
                    >
                        <UndoRedoActions />
                        <Divider
                            orientation="vertical"
                            flexItem
                            sx={{
                                marginLeft: 1,
                                marginRight: 1,
                            }}
                        />
                        <CameraTools />
                        {currentModel && (
                            <>
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{
                                        marginLeft: 1,
                                        marginRight: 1,
                                    }}
                                />
                                <ModalTransformationProvider>
                                    <RemeshButton />
                                </ModalTransformationProvider>
                            </>
                        )}
                    </Stack>
                </Paper>
            </WDSThemeProvider>
        </Box>
    );
};
