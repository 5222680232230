import { useBaseXyz } from '@local/webviz/dist/context';
import type { Color, SurfaceViewState } from '@local/webviz/dist/types/xyz';
import { useRef } from 'react';

import { useSceneObjectDataManager } from 'src/hooks/useSceneObjectDataManager';
import { DEFAULT_GTM_COLOR } from 'src/styles';

const HIGHLIGHT_COLOR = [255, 229, 127] as Color; // amber/A100

export const useHighlightSceneObject = (objectId: string) => {
    const previousObjectColor = useRef<Color | undefined>(undefined);
    const previousObjectIsWireframeEnabled = useRef<boolean | undefined>(undefined);
    const { getState } = useBaseXyz();
    const { updateObjectColor, updateObjectWireframe } = useSceneObjectDataManager();

    const highlightSurface = () => {
        const objectXyzState = getState()[objectId] as SurfaceViewState | undefined;
        previousObjectColor.current = objectXyzState?.color;
        previousObjectIsWireframeEnabled.current = objectXyzState?.wireframe;
        updateObjectColor(objectId, HIGHLIGHT_COLOR);
        updateObjectWireframe(objectId, false);
    };

    const removeHighlight = () => {
        const color = previousObjectColor.current ?? DEFAULT_GTM_COLOR;
        const isWireframeEnabled = previousObjectIsWireframeEnabled.current ?? false;
        updateObjectColor(objectId, color);
        updateObjectWireframe(objectId, isWireframeEnabled);
    };

    return {
        highlightSurface,
        removeHighlight,
    };
};
