import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import IconButton from '@mui/material/IconButton/IconButton';

import { DEFAULT_TOLERANCE } from 'src/apiClients/gtmCompute/gtmComputeApi.constants';
import { GtmMeshTransformationAction } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { AggregateIcon } from 'src/assets/AggregateIcon';
import type { GtmEvoOutputObject } from 'src/gtmProject/Project.types';
import { useParameterizedVolumesManager } from 'src/hooks/modelling/useParameterizedVolumesManager';
import { useVolumesManager } from 'src/hooks/modelling/useVolumesManager';
import { useModalTransformation } from 'src/hooks/transformation/useModalTransformation';
import {
    shouldRenderObject,
    ShouldRunDetectorsOnUpdatedObjects,
} from 'src/hooks/transformation/useTransformationManager';
import { selectCurrentAggregateGeometry } from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { toRequestObject } from 'src/utils/typeTransformations';

export function AggregateControl({
    inputMesh,
    handleAggregateObject,
}: Readonly<{
    inputMesh: GtmEvoOutputObject;
    handleAggregateObject: () => void;
}>) {
    const applyTrace = useTrace('aggregate-control');

    const currentAggregateGeometry = useAppSelector(selectCurrentAggregateGeometry);
    const { resetVolumes } = useVolumesManager();
    const { resetParameterizedVolumes } = useParameterizedVolumesManager();
    const { executeTransformation } = useModalTransformation();

    const performAggregation = async () => {
        await executeTransformation(
            GtmMeshTransformationAction.AddToAggregateGeom,
            shouldRenderObject(currentAggregateGeometry!),
            ShouldRunDetectorsOnUpdatedObjects.Yes,
            [inputMesh],
            {
                aggregateGeomId: toRequestObject(currentAggregateGeometry!),
                tolerance: DEFAULT_TOLERANCE,
                noSelfIntersectionsInParts: true,
            },
            {
                handleAdditionalSideEffects: () => {
                    resetVolumes();
                    resetParameterizedVolumes();
                    handleAggregateObject();
                },
            },
        );
    };

    return (
        <IconButton
            edge="end"
            size="small"
            onClick={performAggregation}
            automation-id={applyTrace(`perform-aggregation-${inputMesh.id}`)}
        >
            <AggregateIcon />
        </IconButton>
    );
}
