import { useDeleteObjectsByIdMutation } from '@api/goose/dist/enhancedGooseClient';
import { useMessagesContext } from '@local/messages/dist/MessagesContext';
import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import { DragAndDrop } from '@local/web-design-system/dist/components/DragAndDrop';
import { NotificationType } from '@local/web-design-system/dist/components/Notification';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import type { BoxProps } from '@mui/material/Box';
import Button from '@mui/material/Button/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { lighten } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useMemo, useRef, useState, forwardRef } from 'react';
import type { ChangeEvent, DragEvent, PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';

import { OverflowTooltipTypography } from 'src/components/OverflowTooltipTypography';
import { METRES_UNIT_VALUE, PROJECT_DISTANCE_UNITS } from 'src/constants';
import type { GtmEvoOutputObject, GtmProjectInput } from 'src/gtmProject';
import { useSceneObjectDataManager } from 'src/hooks';
import { useObjToTriangleMeshUploader } from 'src/hooks/evoContext/useObjToTriangleMeshUploader';
import { useProjectSynchronizer } from 'src/hooks/project/useProjectSynchronizer';
import { selectUserTriangleMeshes } from 'src/store/evo/selectors';
import { addInputObjectsToProject, updateInputObject } from 'src/store/project/projectSlice';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    selectOpenUploadObjectsDialog,
    setOpenUploadObjectsDialog,
} from 'src/store/ui/projectPanel';
import { fileNameExtensionRemover } from 'src/utils';
import {
    CHOOSE_FILES_PROMPT,
    CLOSE_LABEL,
    DELETE_ARTIFACT_ERROR_MESSAGE,
    DRAG_DROP_PROMPT,
    FILE_SIZE_LIMIT_MB,
    getAddButtonLabel,
    UPLOAD_OBJECTS_DIALOG_TITLE,
} from 'src/visualization/ProjectPanel/components/UploadObjectsDialog/UploadObjectsDialog.constants';
import {
    handleDuplicateFileNames,
    validateFiles,
} from 'src/visualization/ProjectPanel/components/UploadObjectsDialog/validators';
import { getAddObjectsToProjectDescription } from 'src/visualization/ProjectPanel/ProjectPanel.constants';

interface FileAndArtifact {
    file: File;
    artifact?: GtmEvoOutputObject;
}

export function UploadObjectsDialog() {
    const applyTrace = useTrace('upload-objects-dialog');
    const { workspaceUuid, orgUuid } = useParams();
    const dispatch = useAppDispatch();
    const [selectedFileAndArtifacts, setSelectedFileAndArtifacts] = useState<FileAndArtifact[]>([]);
    const [selectedFilesErrors, setSelectedFilesErrors] = useState<string[]>([]);
    const [uploadedItems, pendingItems] = useMemo(() => {
        const uploadedFileAndArtifacts: FileAndArtifact[] = [];
        const pendingFileAndArtifacts: FileAndArtifact[] = [];
        selectedFileAndArtifacts.forEach((fileAndArtifact) => {
            if (fileAndArtifact.artifact) {
                uploadedFileAndArtifacts.push(fileAndArtifact);
            } else {
                pendingFileAndArtifacts.push(fileAndArtifact);
            }
        });
        return [uploadedFileAndArtifacts, pendingFileAndArtifacts];
    }, [selectedFileAndArtifacts]);
    const usersTriangleMeshes = useAppSelector(selectUserTriangleMeshes);
    const existingMeshNames = useMemo(
        () =>
            usersTriangleMeshes
                ?.map((artifact) => fileNameExtensionRemover(artifact.name))
                .concat(
                    selectedFileAndArtifacts.map(({ file }) => fileNameExtensionRemover(file.name)),
                ) ?? [],
        [usersTriangleMeshes, selectedFileAndArtifacts],
    );
    const { uploadObjAsTriangleMesh } = useObjToTriangleMeshUploader();
    const { syncProject } = useProjectSynchronizer();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const dragAndDropRef = useRef<HTMLInputElement>(null);
    const [deleteObjectByIdTrigger] = useDeleteObjectsByIdMutation();
    const { addMessage } = useMessagesContext();
    const { loadGtmObject } = useSceneObjectDataManager();
    const openUploadObjectsDialog = useAppSelector(selectOpenUploadObjectsDialog);

    const handleFilesUpload = async (files: File[]) => {
        const [validatedFiles, errors] = validateFiles(files);
        setSelectedFilesErrors(errors);
        const cleanedFiles = handleDuplicateFileNames(validatedFiles, existingMeshNames);
        setSelectedFileAndArtifacts((currentFilesAndArtifacts) => [
            ...currentFilesAndArtifacts,
            ...cleanedFiles.map((file) => ({ file })),
        ]);

        const uploadPromises = cleanedFiles.map((file) =>
            uploadObjAsTriangleMesh(file)
                .then((artifact) => {
                    setSelectedFileAndArtifacts((currentFilesAndArtifacts) =>
                        currentFilesAndArtifacts.map((currentFileAndArtifact) =>
                            currentFileAndArtifact.file === file
                                ? { file, artifact }
                                : currentFileAndArtifact,
                        ),
                    );
                })
                .catch(() => {
                    setSelectedFileAndArtifacts((currentFilesAndArtifacts) =>
                        currentFilesAndArtifacts.filter(
                            (currentFileAndArtifact) =>
                                currentFileAndArtifact.file.name !== file.name,
                        ),
                    );
                }),
        );

        await Promise.allSettled(uploadPromises);
    };

    const handleDragFiles = (event: DragEvent<HTMLInputElement>) => {
        const filesArray = Array.from(event.dataTransfer.files);
        handleFilesUpload(Array.from(filesArray));
    };

    const handleSelectFiles = (event: ChangeEvent<HTMLInputElement>) => {
        const filesArray = event.currentTarget.files;
        if (!filesArray) {
            return;
        }
        handleFilesUpload(Array.from(filesArray));
        fileInputRef.current!.value = '';
    };

    const closeDialog = () => {
        dispatch(setOpenUploadObjectsDialog(false));
        setSelectedFileAndArtifacts([]);
    };

    const handleAddObjects = () => {
        const artifacts: GtmProjectInput[] = [];
        uploadedItems.forEach(({ artifact }) => {
            if (!artifact) {
                return;
            }
            artifacts.push(artifact);

            const { name, id, version } = artifact;
            loadGtmObject(id, version, name);
        });
        dispatch(addInputObjectsToProject(artifacts));
        artifacts.forEach((object) => {
            dispatch(updateInputObject([object.id, { ...object, visible: true }]));
        });
        syncProject({
            description: getAddObjectsToProjectDescription(artifacts),
        });
        closeDialog();
    };

    const handleRemoveError = (index: number) => {
        setSelectedFilesErrors((currentErrors) => currentErrors.filter((_, i) => i !== index));
    };

    const createDeleteArtifactHandler =
        ({ artifact }: FileAndArtifact) =>
        async () => {
            if (!artifact) {
                return;
            }

            try {
                await deleteObjectByIdTrigger({
                    objectId: artifact.id,
                    orgId: orgUuid!,
                    workspaceId: workspaceUuid!,
                }).unwrap();
                setSelectedFileAndArtifacts((fileAndArtifacts) =>
                    fileAndArtifacts.filter(
                        ({ artifact: currentArtifact }) => currentArtifact?.id !== artifact?.id,
                    ),
                );
            } catch (e) {
                addMessage({
                    message: DELETE_ARTIFACT_ERROR_MESSAGE,
                    type: NotificationType.ERROR,
                });
            }
        };

    return (
        <Dialog open={openUploadObjectsDialog} hideBackdrop>
            <DialogTitle automation-id={applyTrace('title')}>
                {UPLOAD_OBJECTS_DIALOG_TITLE}
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Stack
                    sx={(theme) => ({
                        maxHeight: 300,
                        overflowY: 'auto',
                        marginBottom: theme.spacing(1),
                    })}
                >
                    {selectedFilesErrors.map((error, index) => (
                        <Alert
                            key={error}
                            severity="error"
                            action={
                                <IconButton
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        handleRemoveError(index);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={(theme) => ({
                                mb: theme.spacing(1),
                                width: `calc(475px - ${theme.spacing(4)})`,
                                paddingLeft: theme.spacing(2),
                                paddingRight: theme.spacing(2),
                            })}
                        >
                            {error}
                        </Alert>
                    ))}
                </Stack>
                <DragAndDrop enabled onDrop={handleDragFiles}>
                    <DragAndDropBox ref={dragAndDropRef}>
                        <Paper
                            elevation={0}
                            sx={(theme) => ({
                                border: `1px dashed ${theme.palette.divider}`,
                                height: 120,
                                width: 475,
                                backgroundColor: lighten(theme.palette.background.paper, 0.1),
                                alignContent: 'center',
                                textAlign: 'center',
                            })}
                        >
                            <span style={{ fontSize: 46 }}>
                                <UploadFileOutlinedIcon fontSize="inherit" />
                            </span>
                            <Typography variant="subtitle1">
                                {DRAG_DROP_PROMPT}{' '}
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <Link
                                    component="button"
                                    variant="subtitle1"
                                    onClick={() => {
                                        fileInputRef.current?.click();
                                    }}
                                >
                                    {CHOOSE_FILES_PROMPT}
                                </Link>
                                <input
                                    automation-id={applyTrace('file-input')}
                                    ref={fileInputRef}
                                    type="file"
                                    style={{ display: 'none' }}
                                    multiple
                                    onChange={handleSelectFiles}
                                    accept=".obj"
                                />
                            </Typography>
                        </Paper>
                        <Typography variant="body1">{`Max size: ${FILE_SIZE_LIMIT_MB}MB`}</Typography>
                    </DragAndDropBox>
                </DragAndDrop>
                <Stack
                    mt={(theme) => theme.spacing(3)}
                    spacing={1}
                    maxHeight={300}
                    sx={{ overflowY: 'auto' }}
                >
                    {pendingItems.map((fileAndArtifact) => (
                        <UploadFileItem
                            key={fileAndArtifact.file.name}
                            fileAndArtifact={fileAndArtifact}
                            isLoading
                        />
                    ))}
                    {uploadedItems.map((fileAndArtifact) => (
                        <UploadFileItem
                            key={fileAndArtifact.file.name}
                            fileAndArtifact={fileAndArtifact}
                            handleDelete={createDeleteArtifactHandler(fileAndArtifact)}
                        />
                    ))}
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button onClick={closeDialog} color="info">
                    {CLOSE_LABEL}
                </Button>
                <Button
                    automation-id={applyTrace('add-objects-button')}
                    disabled={uploadedItems.length === 0}
                    variant="contained"
                    onClick={handleAddObjects}
                >
                    {getAddButtonLabel(uploadedItems.length)}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function UploadFileItem({
    fileAndArtifact: { file, artifact },
    handleDelete,
    isLoading,
}: {
    fileAndArtifact: FileAndArtifact;
    handleDelete?: () => void;
    isLoading?: boolean;
}) {
    // TODO: handle units for objects
    const [units, setUnits] = useState(METRES_UNIT_VALUE);

    return (
        <Stack direction="row" width={475}>
            <Stack sx={{ flexGrow: 1, maxWidth: 420 }}>
                <Paper
                    sx={
                        isLoading
                            ? {
                                  borderRadius: 0,
                                  borderTopLeftRadius: '4px',
                                  borderTopRightRadius: '4px',
                              }
                            : undefined
                    }
                    elevation={2}
                >
                    <Stack direction="row">
                        <Stack sx={(theme) => ({ padding: theme.spacing(1, 1, 1, 2), width: 210 })}>
                            <OverflowTooltipTypography variant="body2">
                                {file.name}
                            </OverflowTooltipTypography>
                            {artifact && (
                                <Typography variant="caption">
                                    {(file.size / 1024 / 1024).toFixed(2)} MB
                                </Typography>
                            )}
                        </Stack>
                        {artifact && (
                            <>
                                <Divider orientation="vertical" flexItem />
                                <FormControl sx={{ margin: 'auto', width: 150 }} size="small">
                                    <TextField
                                        select
                                        id="key"
                                        value={units}
                                        label="Units"
                                        size="small"
                                        variant="standard"
                                        inputProps={{ sx: { fontSize: 14 } }}
                                        onChange={(e) => setUnits(e.target.value)}
                                    >
                                        {PROJECT_DISTANCE_UNITS.map((unit) => (
                                            <MenuItem key={unit.value} value={unit.value}>
                                                <Typography variant="body2">
                                                    {unit.label}
                                                </Typography>
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </FormControl>
                            </>
                        )}
                    </Stack>
                </Paper>
                {isLoading && (
                    <LinearProgress
                        sx={{ borderBottomRightRadius: '4px', borderBottomLeftRadius: '4px' }}
                    />
                )}
            </Stack>
            {artifact && (
                <IconButton
                    onClick={handleDelete}
                    size="small"
                    sx={(theme) => ({ padding: theme.spacing(2) })}
                >
                    <DeleteIcon />
                </IconButton>
            )}
        </Stack>
    );
}

interface DragAndDropBoxProps extends BoxProps {
    dragEnter?: boolean;
}

const DragAndDropBox = forwardRef<HTMLDivElement, PropsWithChildren<DragAndDropBoxProps>>(
    (props, ref) => {
        // Ignore the dragEnter prop which causes errors if forwarded to the MUI Box component
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { children, dragEnter, ...boxProps } = props;
        return (
            <Box ref={ref} {...boxProps}>
                {children}
            </Box>
        );
    },
);
