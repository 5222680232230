import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import Button from '@mui/material/Button/Button';

import type { GtmMeshDetectSelfIntersectionsParams } from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import {
    GtmMeshDetectorAction,
    GtmMeshTransformationAction,
} from 'src/apiClients/gtmCompute/gtmComputeApi.types';
import { getDetectorParams } from 'src/hooks/defects/useDefectsLoadingManager';
import { useParameterizedVolumesManager } from 'src/hooks/modelling/useParameterizedVolumesManager';
import { useVolumesManager } from 'src/hooks/modelling/useVolumesManager';
import { useObjectManager } from 'src/hooks/project/useObjectManager';
import { useModalTransformation } from 'src/hooks/transformation/useModalTransformation';
import {
    ShouldRenderUpdatedObjects,
    ShouldRunDetectorsOnUpdatedObjects,
} from 'src/hooks/transformation/useTransformationManager';
import { issueDataForObjectAndAction } from 'src/store/issues/selectors';
import {
    selectCurrentAnalyticalModelSettings,
    selectCurrentModelSelectedObject,
} from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import { assert } from 'src/utils/gtmAssert';
import { FIX_SELF_INTERSECTIONS_LABEL } from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/IssuesTab/TransformationAction/TransformationAction.constants';

export const FixSelfIntersections = () => {
    const applyTrace = useTrace('fix-self-intersections');
    const selectedObject = useAppSelector(selectCurrentModelSelectedObject);
    assert(
        selectedObject !== undefined,
        'Self-intersection fixer does not know to which object it applies.',
    );
    const analyticalModelSettings = useAppSelector(selectCurrentAnalyticalModelSettings);
    const selfIntersections = useAppSelector(
        issueDataForObjectAndAction(
            selectedObject!.id,
            GtmMeshDetectorAction.DetectSelfIntersections,
        ),
    );
    const { resetVolumes } = useVolumesManager();
    const { resetParameterizedVolumes } = useParameterizedVolumesManager();
    const { isAggregate } = useObjectManager();
    const { executeTransformation } = useModalTransformation();

    const handleTransformation = () => {
        if (!selfIntersections || !selectedObject || !analyticalModelSettings) {
            return;
        }

        const detectorParams = getDetectorParams(
            analyticalModelSettings,
            GtmMeshDetectorAction.DetectSelfIntersections,
        ) as GtmMeshDetectSelfIntersectionsParams;
        const params = {
            tolerance: detectorParams.tolerance,
            assumeNoSelfIntersectionsInParts: false,
        };

        executeTransformation(
            GtmMeshTransformationAction.Intersect,
            ShouldRenderUpdatedObjects.Yes,
            ShouldRunDetectorsOnUpdatedObjects.Yes,
            [selectedObject],
            params,
            {
                handleAdditionalSideEffects: () => {
                    if (isAggregate(selectedObject.id)) {
                        resetVolumes();
                        resetParameterizedVolumes();
                    }
                },
            },
        );
    };

    return (
        <Button
            automation-id={applyTrace('fix-button')}
            size="small"
            sx={{ width: '100%' }}
            variant="contained"
            onClick={handleTransformation}
        >
            {FIX_SELF_INTERSECTIONS_LABEL}
        </Button>
    );
};
