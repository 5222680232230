import { WDSThemeProvider } from '@local/web-design-system-2';
import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import RollerIcon from '@mui/icons-material/ImagesearchRollerOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import type { SxProps, Theme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useContext, useState } from 'react';

import { OverflowTooltipTypography } from 'src/components/OverflowTooltipTypography';
import { WDS2ThemeContext } from 'src/context/ThemeContext/ThemeContext';
import { ModalTransformationProvider } from 'src/hooks/transformation/useModalTransformation';
import { selectDoesCurrentModelSelectedObjectHaveIssues } from 'src/store/issues/selectors';
import { selectCurrentModelSelectedObject } from 'src/store/project/selectors';
import { useAppSelector } from 'src/store/store';
import {
    sceneObjectById,
    selectNumOfTrianglesForCurrentModelSelectedObject,
    selectNumOfVerticesForCurrentModelSelectedObject,
} from 'src/store/visualization/selectors';
import { DEFAULT_PANEL_WIDTH } from 'src/styles';
import { fileNameExtensionRemover } from 'src/utils';
import { IssuesTab } from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/IssuesTab';
import { SettingsTab } from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/components/SettingsTab';
import {
    ISSUES_LABEL,
    SETTINGS_LABEL,
    TRIANGLES_LABEL,
    VERTICES_LABEL,
} from 'src/visualization/SettingsPanel/components/ObjectSettingsPanel/ObjectsSettingsPanel.constants';

const enum ObjectSettingsPanelTabSelection {
    Issues,
    Settings,
}

interface ObjectSettingsPanelProps {
    sx?: SxProps<Theme>;
}

const getWarningBadgeSx = (isSelected: boolean, invisible: boolean) => (theme: Theme) => ({
    '.MuiBadge-badge': {
        transform: `translate(${theme.spacing(2)}, -${theme.spacing(1)})`,
        border: `1px solid ${invisible ? 'transparent' : theme.palette.warning.main}`,
        backgroundColor: isSelected && !invisible ? theme.palette.warning.main : 'transparent',
    },
});

export function ObjectsSettingsPanel({ sx }: ObjectSettingsPanelProps) {
    const { theme: appTheme } = useContext(WDS2ThemeContext);
    const currentModelSelectedObject = useAppSelector(selectCurrentModelSelectedObject);
    const doesCurrentModelSelectedObjectHaveIssues = useAppSelector(
        selectDoesCurrentModelSelectedObjectHaveIssues,
    );
    const numOfTriangles = useAppSelector(selectNumOfTrianglesForCurrentModelSelectedObject);
    const numOfVertices = useAppSelector(selectNumOfVerticesForCurrentModelSelectedObject);
    const [tabSelection, setTabSelection] = useState(ObjectSettingsPanelTabSelection.Issues);
    const applyTrace = useTrace('object-settings-panel');

    const handleTabSelection = (_e: unknown, newValue: ObjectSettingsPanelTabSelection) => {
        setTabSelection(newValue);
    };

    // Disable the appearance tab if the object is not loaded. This may change in the future, but for now, we don't
    // have the goose object to change appearance settings within if the object is not loaded.
    const sceneObject = useAppSelector(sceneObjectById(currentModelSelectedObject!.id));
    const isModelObjectLoaded: boolean =
        sceneObject !== undefined && sceneObject.gooseObject !== undefined;

    return (
        <Box sx={sx} automation-id={applyTrace('root')}>
            <WDSThemeProvider themeMode={appTheme}>
                <Stack spacing={2}>
                    <Paper sx={{ width: DEFAULT_PANEL_WIDTH }} elevation={4}>
                        <Stack p={2}>
                            <OverflowTooltipTypography>
                                {fileNameExtensionRemover(currentModelSelectedObject?.name)}
                            </OverflowTooltipTypography>
                            <Stack direction="row" spacing={1}>
                                <Typography
                                    color="secondary"
                                    variant="body2"
                                >{`${TRIANGLES_LABEL}: ${numOfTriangles}`}</Typography>
                                <Typography
                                    color="secondary"
                                    variant="body2"
                                >{`${VERTICES_LABEL}: ${numOfVertices}`}</Typography>
                            </Stack>
                        </Stack>
                        <Tabs
                            sx={(theme) => ({
                                borderBottom: `${theme.palette.divider} 1px solid`,
                            })}
                            value={tabSelection}
                            onChange={handleTabSelection}
                        >
                            <Tab
                                label={
                                    <Badge
                                        sx={getWarningBadgeSx(
                                            tabSelection === ObjectSettingsPanelTabSelection.Issues,
                                            !doesCurrentModelSelectedObjectHaveIssues,
                                        )}
                                        variant="dot"
                                    >
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <WarningIcon fontSize="small" />
                                            <Typography
                                                variant="button"
                                                sx={{
                                                    textTransform: 'capitalize',
                                                    fontSize: '12px',
                                                }}
                                            >
                                                {ISSUES_LABEL}
                                            </Typography>
                                        </Stack>
                                    </Badge>
                                }
                                value={ObjectSettingsPanelTabSelection.Issues}
                                automation-id={applyTrace('issues-tab')}
                            />
                            <Tab
                                label={
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <RollerIcon fontSize="small" />
                                        <Typography
                                            variant="button"
                                            sx={{
                                                textTransform: 'capitalize',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {SETTINGS_LABEL}
                                        </Typography>
                                    </Stack>
                                }
                                value={ObjectSettingsPanelTabSelection.Settings}
                                automation-id={applyTrace('appearance-tab')}
                                disabled={!isModelObjectLoaded}
                            />
                        </Tabs>
                        {tabSelection === ObjectSettingsPanelTabSelection.Issues && (
                            <ModalTransformationProvider>
                                <IssuesTab />
                            </ModalTransformationProvider>
                        )}
                        {tabSelection === ObjectSettingsPanelTabSelection.Settings &&
                            isModelObjectLoaded && <SettingsTab />}
                    </Paper>
                </Stack>
            </WDSThemeProvider>
        </Box>
    );
}
